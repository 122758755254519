import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import ContentLayout from "../components/contentLayout"
import SEO from "../components/SEO/seo"
import { GatsbyImage } from "gatsby-plugin-image"

const ReviewCardContainer = styled.div`
  display: grid;
  grid-template-columns: 32% 32%;
  justify-content: center;
  align-content: start;
  grid-column-gap: 40px;
  margin-bottom: 70px;
  @media (max-width: 800px) {
    grid-template-columns: 42% 42%;
  }
  @media (max-width: 600px) {
    grid-template-columns: 80%;
  }
`
const StyledReviewCard = styled.div`
  display: grid;
  padding: 20px;
  border: 1px solid #c2c8ce;
  border-radius: 15px;
  align-content: start;
`
const RatingStarContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-column-gap: 6px;
  justify-content: start;
  align-content: center;
`
const RatingStar = styled.div`
  color: #ffc107;
  font-size: 20px;
`
const CardText = styled.p`
  color: #3d4d69;
  font-size: 18px;
  margin-bottom: 5px;
  @media (max-width: 1450px) {
    font-size: 15px;
  }
`
const Underline = styled.div`
  background-color: #33cbcf;
  width: 25%;
  height: 2px;
  margin-top: 30px;
`
const ReviewPersonContainer = styled.div`
  display: grid;
  grid-template-columns: 60px auto;
  grid-column-gap: 20px;
  justify-content: start;
  align-content: center;
  margin-top: 30px;
`
const PersonImage = styled.div`
  border-radius: 50%;
`
const ReviewCardColumnContainer = styled.div`
  display: grid;
  grid-row-gap: 40px;
  align-content: start;
  @media (max-width: 600px) {
    margin-bottom: 40px;
  }
`
const VideoContainer = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25%;
  grid-gap: 40px;
  justify-content: center;
  margin-bottom: 80px;
  @media (max-width: 800px) {
    grid-template-columns: 42% 42%;
  }
  @media (max-width: 575px) {
    grid-template-columns: 80%;
    grid-gap: 20px;
  }
`
const VideoResponsive = styled.div`
  overflow: hidden;
  padding-bottom: 56%;
  position: relative;
  height: 0;
`
const StyledIframe = styled.iframe`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
`

const ReviewCard = props => (
  <StyledReviewCard>
    <RatingStarContainer>
      <RatingStar>☆</RatingStar>
      <RatingStar>☆</RatingStar>
      <RatingStar>☆</RatingStar>
      <RatingStar>☆</RatingStar>
      <RatingStar>☆</RatingStar>
    </RatingStarContainer>
    <CardText>{props.text1}</CardText>
    {props.text2 && <CardText> {props.text2} </CardText>}
    {props.text3 && <CardText> {props.text3} </CardText>}
    <Underline />
    <ReviewPersonContainer>
      <PersonImage>{props.personImage}</PersonImage>
      <CardText> {props.personName} </CardText>
    </ReviewPersonContainer>
  </StyledReviewCard>
)

export default ({ data, location }) => (
  <>
    <SEO
      title=" | Stories from our happy customers"
      description="Check out marketing gurus' and influencers' reviews on Groupboss. Read their testimonials and learn how it can transform your business. "
      pathname={location.pathname}
    />
    <ContentLayout
      image={
        <GatsbyImage
          image={data.customer_feedback.childImageSharp.gatsbyImageData}
          alt="cussotmer feedback"
        />
      }
      contentH1="Happy users everywhere!"
      contentP1="Having almost 5 user rating, we appreciate our users who have taken time to write us their amazing feedbacks"
      imageAlign="1/2"
      textAlign="1/1"
      imageGridColumn="80%"
      textGridColumn="78%"
      gridColumns="34% 35%"
      justifyContent="center"
    />

    <VideoContainer>
      <VideoResponsive>
        <StyledIframe
          src="https://www.youtube.com/embed/s7T9oo30N6c"
          title="Collect Emails from your facebook groups and grow email lists like a champ"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </VideoResponsive>
      <VideoResponsive>
        <StyledIframe
          src="https://www.youtube.com/embed/A2fyJuyAi_U"
          title="Collect Emails from your facebook groups and grow email lists like a champ"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </VideoResponsive>
      <VideoResponsive>
        <StyledIframe
          src="https://www.youtube.com/embed/b6kB2JzEGhE"
          title="Collect Emails from your facebook groups and grow email lists like a champ"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </VideoResponsive>
      <VideoResponsive>
        <StyledIframe
          src="https://www.youtube.com/embed/UMnnr7q77Bo"
          title="Collect Emails from your facebook groups and grow email lists like a champ"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </VideoResponsive>
      <VideoResponsive>
        <StyledIframe
          src="https://www.youtube.com/embed/lBJOHwOs2us"
          title="Collect Emails from your facebook groups and grow email lists like a champ"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </VideoResponsive>
      <VideoResponsive>
        <StyledIframe
          src="https://www.youtube.com/embed/aXoVo49zNbw"
          title="Collect Emails from your facebook groups and grow email lists like a champ"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </VideoResponsive>
      <VideoResponsive>
        <StyledIframe
          src="https://www.youtube.com/embed/GdHTKz_oor0"
          title="Collect Emails from your facebook groups and grow email lists like a champ"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </VideoResponsive>
    </VideoContainer>

    <ReviewCardContainer>
      <ReviewCardColumnContainer>
        <ReviewCard
          text1="For a long time we have been looking for a solution like ‘Groupboss’.
          It’s an absolute lifesaver for us.Groupboss will help you to retain 
          your data forever,there is no chance of losing any data."
          personImage={
            <GatsbyImage
              image={data.client.childImageSharp.gatsbyImageData}
              style={{ borderRadius: `50%` }}
              alt="Abdul Mukati"
            />
          }
          personName="Abdul A Mukati"
        />
        <ReviewCard
          text1="Those E-mails are gold. The missing piece of software that I
          needed to grow my E-mail lists. If you are trying to build
          an active Facebook group then it’s a must having tool."
          personImage={
            <GatsbyImage
              image={data.client_1.childImageSharp.gatsbyImageData}
              style={{ borderRadius: `50%` }}
              alt="Bruce karaft"
            />
          }
          personName="Bruce Kraft Jr."
        />
        <ReviewCard
          text1="Hey Groupboss Team, I just wanted to take a second to thank you for the excellent support I just had! I had a problem with the extension and dealing with a growing group so I had this to be fixed asap! You contacted me and took the time to troubleshoot my bug and it really made my day! Thanks again and keep up the good work "
          personImage={
            <GatsbyImage
              image={data.client_4.childImageSharp.gatsbyImageData}
              style={{ borderRadius: `50%` }}
              alt="client 4"
            />
          }
          personName="Alexandre P"
        />
      </ReviewCardColumnContainer>

      <ReviewCardColumnContainer>
        <ReviewCard
          text1="Highly recommend it for anyone who has a business on the internet. Groupboss is the most effective way, which I've I never think of it before, to grow your email list consistently from Facebook group. It's just a simple tactic but works like a charm. I love it too much and I regret why I know it too late!"
          personImage={
            <GatsbyImage
              image={data.client_2.childImageSharp.gatsbyImageData}
              style={{ borderRadius: `50%` }}
              alt="cuong tran"
            />
          }
          personName="Cường Trần"
        />
        <ReviewCard
          text1="Omar and his team make a strong effort to solve every problem they are very very accessible. The UI is very clean and intuitive .  It the better of all of the other systems I purchased. This product is ready for the big time...  i dont work for them and i actually paid for the program"
          personImage={
            <GatsbyImage
              image={data.client_3.childImageSharp.gatsbyImageData}
              style={{ borderRadius: `50%` }}
              alt="Trevor otts"
            />
          }
          personName="Trevor Otts"
        />
      </ReviewCardColumnContainer>
    </ReviewCardContainer>
  </>
)

export const query = graphql`
  {
    customer_feedback: file(relativePath: { eq: "customer_feedback.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    client: file(relativePath: { eq: "abdul_a_mukati.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    client_1: file(relativePath: { eq: "BruceKraftJr.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    client_2: file(relativePath: { eq: "client2.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    client_3: file(relativePath: { eq: "client3.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    client_4: file(relativePath: { eq: "shrekanth.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`
